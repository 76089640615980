import { useMemo } from 'react';
import { SellerProfession_VariantType } from '@fiverr-private/bianka_js/protobuf/bianka/event/activity/v2/extension/seller_profession';
import { RoleCatalogType } from '../../../../gql-operations/gql-generated';
import { useProfessionBIExtensionData } from './useProfessionBIExtensionData';

const MAP_VARIANT_TYPE_TO_ROLE_CATALOG_TYPE = {
    [SellerProfession_VariantType.MD]: RoleCatalogType.MetadataValue,
    [SellerProfession_VariantType.ROLE]: RoleCatalogType.Role,
    [SellerProfession_VariantType.SERVICE]: RoleCatalogType.Service,
};

export const useContactMeProfessionBIExtensionData = () => {
    const extensionData = useProfessionBIExtensionData();

    const data = useMemo(
        () =>
            extensionData?.profession
                ? {
                      profession: {
                          profession_id: extensionData.profession.id,
                          seller_profession_id: extensionData.profession.sellerProfessionId,
                          variant_id: extensionData.variant?.id,
                          variant_type: extensionData.variant
                              ? MAP_VARIANT_TYPE_TO_ROLE_CATALOG_TYPE[extensionData.variant.type].toLowerCase()
                              : null,
                          computed_title: extensionData.computedTitle,
                      },
                  }
                : {},
        [extensionData]
    );

    return data;
};
