import React from 'react';
import { translate } from '@fiverr-private/i18n-react';
import pathfinder from '@fiverr-private/pathfinder';
import { useSellerAiModels } from '@fiverr-private/seller_pages_toolbox';
import { Typography } from '@fiverr-private/typography';
import { TagCollection, Tag } from '@fiverr-private/data_display';
import { useSellerDisplayName } from '../../hooks/useSellerDisplayName';

export const AiModelTags = () => {
    const sellerAIModels = useSellerAiModels();
    const sellerDisplayName = useSellerDisplayName();

    if (!sellerAIModels) return null;

    const uniqueLeafs = new Set();
    const modelsWithUniqueLeafs = sellerAIModels.filter((model) => {
        const { leaf, vertical } = model.catalog || {};
        if (!leaf?.slug || !vertical?.slug) return false;

        if (uniqueLeafs.has(leaf.slug)) return false;

        uniqueLeafs.add(leaf.slug);
        return true;
    });

    const title = translate('seller_page_perseus.ai_models.tags.title', { params: { sellerDisplayName } });

    return (
        <>
            <Typography as="h4" color="black" marginBottom="6">
                {title}
            </Typography>
            <TagCollection gap="3" variant="outline">
                {modelsWithUniqueLeafs.map((model) => {
                    const { leaf, vertical } = model.catalog || {};

                    if (!leaf.slug || !vertical.slug) return null;

                    const url = pathfinder('go_explore_leaf_category_page', {
                        vertical: vertical.slug,
                        leaf: leaf.slug,
                    });

                    return (
                        <Tag key={model.id} href={url} ariaLabel={leaf.name}>
                            {leaf.name}
                        </Tag>
                    );
                })}
            </TagCollection>
        </>
    );
};
