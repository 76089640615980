import React, { useState } from 'react';
import { useAtomValue } from 'jotai';
import { GigCardListings } from '@fiverr-private/gig_listings';
import { Button } from '@fiverr-private/button';
import { I18n } from '@fiverr-private/i18n-react';
import { CURRENCY_CODES } from '@fiverr-private/localization';
import { getContext } from '@fiverr-private/fiverr_context';
import { useLocalizationContext, useMonitoring } from '@fiverr-private/seller_pages_toolbox';
import { useCurrentUserCollections } from '../../hooks/useCollections';
import { APP_NAME } from '../../../../configuration/constants';
import { gigsAtom, sellerHasProfessionsAtom } from '../../atoms';
import { useGigsWithoutRating } from '../../hooks/useGigsWithoutRating';
import { DEFAULT_COUNT_ON_PAGE } from './constants';
import { GigsProps } from './types';

export const Gigs = ({ showTranslatedUGC }: GigsProps) => {
    const [showAllGigs, setShowAllGigs] = useState(false);
    const { userId, pageCtxId, abTests, isTouch } = getContext();
    const { fireEvents, Providers } = useMonitoring();
    const { currency } = useLocalizationContext();
    const [collections, setCollections] = useCurrentUserCollections();
    const sellerHasProfessions = useAtomValue(sellerHasProfessionsAtom);
    const gigs = useAtomValue(gigsAtom);

    const showAllButton = showAllGigs ? false : gigs.length > DEFAULT_COUNT_ON_PAGE;
    const _gigs = showAllGigs ? gigs : gigs.slice(0, DEFAULT_COUNT_ON_PAGE);
    const gigsToShow = useGigsWithoutRating(_gigs, sellerHasProfessions);

    const context = {
        userId,
        currency,
        pageCtxId,
        abTestsProps: abTests,
        currentPage: 'seller_page',
        sourcePage: 'seller_page',
        viewportType: 'seller_page.gigs',
    };

    const features = {
        gigInNewTab: true,
        priceFormatting: true,
        useForcePriceRounding: currency.name !== CURRENCY_CODES.USD,
    };

    const collectProps = {
        collections,
        modalCustomName: APP_NAME,
        isLoggedIn: !!userId,
        onUpdateCollections: setCollections,
    };

    const onViewAllBI = () => {
        fireEvents({
            [Providers.BigQuery]: {
                type: 'seller_page_clicked_on_show_more_service',
            },
            [Providers.Mixpanel]: {
                type: 'seller page - see all services click',
            },
        });
    };

    const onViewAll = () => {
        onViewAllBI();
        setShowAllGigs(true);
    };

    if (gigs.length === 0) {
        return null;
    }

    return (
        <section>
            <header>
                <h4 className="m-b-20">
                    <I18n k="seller_page_perseus.gigs.title" />
                </h4>
            </header>
            <GigCardListings
                gigs={gigsToShow}
                context={context}
                features={features}
                isTouch={isTouch}
                collectProps={collectProps}
                showTranslatedUGC={showTranslatedUGC}
                isExperiential
                hideSellerInfo
            />
            {showAllButton && (
                <Button className="m-t-20 view-all-btn" variant="outline" onClick={onViewAll}>
                    <I18n k="seller_page_perseus.gigs.view_all" params={{ count: gigs.length }} />
                </Button>
            )}
        </section>
    );
};
