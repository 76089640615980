import { useAtomValue } from 'jotai';
import { openFloatingChat } from '@fiverr-private/go_floating_chat_triggers';
import { FederatedSeller } from '../types';
import { isFloatingChatActiveAtom } from '../atoms';
import { useSellerData } from './useSellerData';
import { useIsSellerOnline } from './useIsSellerOnline';

export const useFloatingChatTrigger = () => {
    const { displayName, username, profileImageUrl } = useSellerData(selectSellerSlice);
    const isFloatingChatEnabled = useAtomValue(isFloatingChatActiveAtom);
    const isOnline = useIsSellerOnline();
    return {
        isFloatingChatEnabled,
        openFloatingChat: () =>
            openFloatingChat({
                data: {
                    recipientUsername: username,
                    seller: {
                        username,
                        name: displayName,
                        imageSrc: profileImageUrl,
                        isOnline,
                    },
                },
            }),
    };
};

function selectSellerSlice(seller: FederatedSeller) {
    return {
        approvedGigsCount: seller.approvedGigsCount,
        username: seller.user.name,
        displayName: seller.user.profile.displayName,
        isActivationCompleted: seller.user.isActivationCompleted,
        profileImageUrl: seller.user.profileImageUrl,
    };
}
