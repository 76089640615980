import { useMemo } from 'react';
import { useRoleVariation } from '../../../hooks/useRoleVariation';
import { useSelectedRole } from '../../../hooks/useSelectedRole';
import { ProfessionBIExtensionData } from '../types';
import { buildProfessionBIExtension } from './buildProfessionBIExtension';

/*
 * Creates an object with the profession and variant data for the `SellerProfession` BI extension.
 * Source: https://github.com/fiverr/bianka-apis/blob/master/proto/bianka/event/activity/v2/extension/seller_profession.proto
 */
export const useProfessionBIExtensionData = () => {
    const selectedRole = useSelectedRole();
    const roleVariation = useRoleVariation();

    const selectedRoleVariationId = selectedRole ? roleVariation[selectedRole.id] : undefined;

    const data: ProfessionBIExtensionData = useMemo<ProfessionBIExtensionData>(
        () => buildProfessionBIExtension(selectedRole, selectedRoleVariationId),
        [selectedRole, selectedRoleVariationId]
    );

    return data;
};
