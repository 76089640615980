import React, { useState, useCallback, useRef } from 'react';
import classnames from 'classnames';
import pathfinder from '@fiverr-private/pathfinder';
import { useBreakpoints } from '@fiverr-private/business_blocks';
import { useSuccessSignCtaEvent, BUSINESS_TRIGGER_CTA } from '@fiverr-private/business_success_sign_modal';
import { logger } from '@fiverr-private/obs';
import { Button } from '@fiverr-private/button';
import { PaperPlaneIcon, SpinnerAnimatedIcon } from '@fiverr-private/icons';
import { I18n, translate } from '@fiverr-private/i18n-react';
import { white } from '@fiverr-private/sass/helpers';
import { getContext } from '@fiverr-private/fiverr_context';
import { useCurrentPageName } from '../../../hooks/useCurrentPageName';
import { mixpanel, useMonitoring } from '../../../utils/monitoring';
import { useSellerData } from '../../../hooks/useSellerData';
import { useIsSelfView } from '../../../hooks/useIsSelfView';
import { useSellerVacation } from '../../../hooks/useSellerVacation';
import { useFloatingChatTrigger } from '../../../hooks/useFloatingChatTrigger';
import { useIsActivationCompletedAtom } from '../../../hooks/useIsActivationCompletedAtom/useIsActivationCompletedAtom';
import { getWindow } from '../../../utils/getGlobals';
import { raiseError } from '../../../utils/raiseError';
import { contactMeClicked } from '../../../utils/triggerGtmEvent';
import { SUPPORTED_ACTIONS } from '../../../constants';
import { UserActivationModalWrapper } from '../../UserActivationModalWrapper';
import { FederatedSeller } from '../../../types';
import { useContactDataClick } from './useContactDataClick';
import { ContactButtonProps } from './types';
import styles from './index.module.scss';
import { ContactButtonContent } from './ContactButtonContent';

const T_PREFIX = 'seller_pages_toolbox.contact';

export const ContactButton: React.FC<ContactButtonProps> = ({
    title,
    className,
    buttonClassName,
    size,
    onContactOpen,
    biEnrichment = {},
    triggerId,
    triggerCopy = title || translate(`${T_PREFIX}.button`),
    triggerPlacement,
    children,
    reportBI = true,
}) => {
    const { userId } = getContext();

    const isLoggedIn = !!userId;
    const isSelfView = useIsSelfView();
    const source = useCurrentPageName();
    const { approvedGigsCount, displayName, username } = useSellerData(selectSellerSlice);
    const [activateUserModal, setRenderAndOpenUserActivationModal] = useState(false);
    const { isFloatingChatEnabled, openFloatingChat } = useFloatingChatTrigger();
    const buttonRef = useRef<HTMLDivElement>(null);
    const { fireEvents, Providers } = useMonitoring();
    const { isOnVacation, isContactAllowed } = useSellerVacation();
    const { isMD } = useBreakpoints();
    const isActivationCompleted = useIsActivationCompletedAtom();

    const conversationUrl = pathfinder('conversation', { id: username });
    const isVisible = buttonRef.current ? !!buttonRef.current?.offsetParent : false;
    const userHasPendingState = !isActivationCompleted;

    const {
        isLoading,
        modalInitialData,
        isContactClicked,
        setIsContactClicked,
        setEnableFetchData,
        isContactClickReady,
    } = useContactDataClick({
        username,
        isVisible,
        isLoggedIn,
        isMD,
        userHasPendingState,
        onError: () => raiseError(),
    });

    const redirectToInbox = useCallback(() => {
        mixpanel.redirectToInbox({
            priorInteraction: !!modalInitialData?.priorInteraction,
            source,
        });
        getWindow()?.location.assign(conversationUrl);
    }, [conversationUrl, modalInitialData, source]);

    const isLoadingIcon = isContactClicked && isLoading;
    const sellerName = displayName || username;
    const emptyGigsList = !approvedGigsCount;
    const isContactDisabled = () => {
        if (isSelfView || emptyGigsList) {
            return true;
        } else if (isOnVacation) {
            return !isContactAllowed;
        }
        return false;
    };

    const onOpenBI = () => {
        if (!reportBI) {
            return;
        }

        fireEvents({
            [Providers.BigQuery]: {
                type: 'seller_page.contact_me',
                event_source: source,
                element: {
                    type: 'button',
                },
                ...biEnrichment,
            },
            [Providers.Mixpanel]: {
                type: 'clicked contact me',
                source,
            },
        });
    };

    const onCloseActivateModal = useCallback(() => setRenderAndOpenUserActivationModal(false), []);

    const onContactClick = async (): Promise<any> => {
        const { userId } = getContext();

        setIsContactClicked(true);
        contactMeClicked();
        onOpenBI();
        let data: any = {};
        try {
            data = await isContactClickReady();
        } catch (originalError) {
            const err = new Error('Failed to get isContactClickReady data');

            logger.error(err, {
                cause: originalError,
                isSelfView,
                sellerName,
                userId,
            });
        }

        if (modalInitialData?.priorInteraction || data?.priorInteraction) {
            redirectToInbox();
            return;
        }

        if (isLoggedIn) {
            if (userHasPendingState) {
                setRenderAndOpenUserActivationModal(true);
                return false;
            }
            if (isFloatingChatEnabled) {
                openFloatingChat();
            } else {
                onContactOpen();
            }
        } else {
            const win = getWindow();

            if (win) {
                win.openSignUpModal?.(
                    {
                        triggerCta: {
                            source,
                            type: BUSINESS_TRIGGER_CTA.CONTACT,
                            data: { sellerName },
                        },
                        ...(triggerId && {
                            triggerId,
                            triggerCopy,
                            triggerPlacement,
                        }),
                    },
                    { source }
                );
            }
        }
    };

    useSuccessSignCtaEvent({
        source,
        type: BUSINESS_TRIGGER_CTA.CONTACT,
        callback: onContactClick,
    });

    const onMouseEnter = () => setEnableFetchData(true);

    const containerProps = isMD
        ? {
              onClick: onContactClick,
              onMouseEnter,
          }
        : {
              href: conversationUrl,
          };

    return (
        <div className={className} ref={buttonRef}>
            {children ? (
                !isContactDisabled() && <ContactButtonContent {...containerProps}>{children}</ContactButtonContent>
            ) : (
                <Button
                    size={size}
                    className={classnames(styles.contactButton, buttonClassName, 'flex-center', {
                        [styles.buttonWithGradient]: isFloatingChatEnabled,
                    })}
                    disabled={isContactDisabled()}
                    {...containerProps}
                >
                    <span className="flex flex-center">
                        {isLoadingIcon ? <SpinnerAnimatedIcon color={white} /> : <PaperPlaneIcon color={white} />}
                        <span className="p-l-8">{title || <I18n k={`${T_PREFIX}.button`} />}</span>
                    </span>
                </Button>
            )}
            <UserActivationModalWrapper
                isOpen={activateUserModal}
                onClose={onCloseActivateModal}
                action={SUPPORTED_ACTIONS.CONTACT_SELLER}
            />
        </div>
    );
};

function selectSellerSlice(seller: FederatedSeller) {
    return {
        approvedGigsCount: seller.approvedGigsCount,
        username: seller.user.name,
        displayName: seller.user.profile.displayName,
        isActivationCompleted: seller.user.isActivationCompleted,
    };
}
