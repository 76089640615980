export interface Rollouts {
    [key: string]: boolean;
}

export const ROLLOUTS = {
    GRIZZLIES_TRADER_DETAILS_DISPLAYING: 'grizzlies_trader_details_displaying',
    QUALAS_NEW_LEVEL_SYSTEM_CONSUMERS_MIGRATION: 'qualas_new_level_system_consumers_migration',
    ZEBRAS_HOURLY_RATES: 'zebras_hourly_rate',
    CHEETAHS_SELLER_ROLE_PAGE_RELEASE_TWO: 'cheetahs_seller_role_page_release_two',
    STARLINGS_BOOSTING_NEW_GIGS: 'starlings_boosting_new_gigs',
    STARLINGS_FIVERR_GO_FLOATING_CHAT: 'starlings_fiverr_go_floating_chat',
    STARLINGS_FIVERR_GO_INBOX: 'starlings_fiverr_go_inbox',
};
