import React from 'react';
import { isEmpty } from 'lodash';
import pathfinder from '@fiverr-private/pathfinder';
import { translate } from '@fiverr-private/i18n-react';
import {
    NavigationCardCarousel,
    NavigationCardProps,
    Status,
    theme as goTheme,
    BiEventData,
    ModelGradientProps,
} from '@fiverr-private/go_shared_ui';
import { Container } from '@fiverr-private/layout_components';
import { SellerAiModelsData } from '../../../server/types/SellerAiModelsData';

interface AiDeliveryBadgeTooltipProps {
    aiModels: SellerAiModelsData;
    sellerName: string;
    description?: string;
    biEventData?: BiEventData;
}
export const AiDeliveryBadgeTooltip: React.FC<AiDeliveryBadgeTooltipProps> = ({
    aiModels,
    sellerName,
    description,
    biEventData,
}) => {
    if (isEmpty(aiModels)) return null;

    const aiModelCards: NavigationCardProps[] = aiModels.map(({ id, name, theme }) => {
        const modelGradient: ModelGradientProps = {
            ...theme,
            animate: 'hover',
            scale: 'm',
            rotate: 0,
        };

        return {
            id,
            title: name,
            cardSize: 'sm',
            modelGradientProps: modelGradient,
            cardBackground: goTheme.colors.surfaceOne,
            shouldShowButton: true,
            url: pathfinder(
                'ai_hub_playground_create_tab_new_model_conversation',
                {
                    seller_username: sellerName,
                    model_slug: id,
                },
                {
                    query: {
                        ...(biEventData?.pageName && { source: `${biEventData?.pageName}_badge` }),
                    },
                }
            ),
        };
    });

    return (
        <Container width="min-content" backgroundColor="black" padding="4" borderRadius="2xl" marginTop="1">
            <Status text={translate('seller_pages_toolbox.ai_models_carousel.new')} />
            <NavigationCardCarousel
                tiles={aiModelCards}
                textColor="white"
                description={description}
                carouselProps={{ marginTop: '1' }}
                biEventData={biEventData}
            />
        </Container>
    );
};
