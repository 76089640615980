import React, { useMemo } from 'react';
import classNames from 'classnames';
import { CheckIcon } from '@fiverr-private/visuals';
import { translate } from '@fiverr-private/i18n-react';
import { getCategoriesText } from '../../Expertise/utils';
import { useSellerData } from '../../../hooks/useSellerData';
import { FederatedSeller } from '../../../types';
import styles from './styles.module.scss';
import { ProSubcategoriesProps } from './types';

const COPY_PREFIX = 'seller_pages_toolbox.pro_overview.pro_subcategories';

export const ProSubcategories = ({ titleClassName, itemClassName }: ProSubcategoriesProps) => {
    const { proSubCategories } = useSellerData(selectSellerSlice);

    const sortedSubcategoriesNames = useMemo(() => getCategoriesText(proSubCategories).sort(), [proSubCategories]);

    return (
        <>
            <p className={classNames('m-b-12', 'text-semi-bold', styles.title, titleClassName)}>
                {translate(`${COPY_PREFIX}.title`)}
            </p>
            <ul aria-label="Pro SubCategories" className={classNames('flex', styles.list)}>
                {sortedSubcategoriesNames.map((name) => (
                    <li
                        className={classNames('flex', 'flex-items-baseline', styles.item, itemClassName)}
                        key={`subcategory-${name}`}
                    >
                        <span className={classNames('flex-center', 'm-r-8', styles.iconContainer)}>
                            <CheckIcon size="sm" />
                        </span>
                        <p className={styles.subcategoryLabel}>{name}</p>
                    </li>
                ))}
            </ul>
        </>
    );
};

function selectSellerSlice(seller: FederatedSeller) {
    return {
        proSubCategories: seller.proSubCategories,
    };
}
