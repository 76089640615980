import * as React from 'react';
import { any } from 'prop-types';
import { QueryClient, QueryClientProvider } from 'react-query';
import {
    AtomsProvider,
    consultationDataAtom,
    LocalizationContextProvider,
    localizationDataAtom,
    PageName,
    pageNameAtom,
    sellerAtom,
    isActivationCompletedAtom,
    sellerAiModelsAtom,
    isFloatingChatActiveAtom,
} from '@fiverr-private/seller_pages_toolbox';
import { SellerPage } from '../../components/SellerPage';
import { sellerHasProfessionsAtom, gigsAtom, reviewsDataAtom, rolloutsAtom } from '../../atoms';
import { AppProps } from './types';

const ReactQueryClient = new QueryClient();

const App = ({
    currency,
    seller,
    sellerHasProfessions,
    reviewsData,
    consultationData,
    localizationData,
    gigsData,
    rollouts,
    isActivationCompleted,
    sellerAiModelsData,
    isFloatingChatEnabled,
}: AppProps) => (
    <AtomsProvider
        atoms={[
            [sellerAtom, seller],
            [sellerHasProfessionsAtom, sellerHasProfessions],
            [reviewsDataAtom, reviewsData],
            [consultationDataAtom, consultationData || {}],
            [localizationDataAtom, localizationData],
            [pageNameAtom, 'seller_page' as PageName],
            [gigsAtom, gigsData],
            [rolloutsAtom, rollouts],
            [isActivationCompletedAtom, isActivationCompleted],
            [sellerAiModelsAtom, sellerAiModelsData],
            [isFloatingChatActiveAtom, isFloatingChatEnabled],
        ]}
    >
        <QueryClientProvider client={ReactQueryClient}>
            <LocalizationContextProvider currency={currency}>
                <SellerPage />
            </LocalizationContextProvider>
        </QueryClientProvider>
    </AtomsProvider>
);

App.propTypes = {
    currency: any,
};

export default App;
