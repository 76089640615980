import { useMemo } from 'react';
import { useAtomValue } from 'jotai';
import { GigData } from '@fiverr-private/seller_pages_toolbox';
import { rolloutsAtom } from '../atoms';
import { ROLLOUTS } from '../../server/middlewares/addRolloutsData/constants';

export const useGigsWithoutRating = (gigs: GigData[], enabled = true) => {
    const rollouts = useAtomValue(rolloutsAtom);
    const inGigBoostingRollout = rollouts[ROLLOUTS.STARLINGS_BOOSTING_NEW_GIGS];
    const cutRating = enabled && inGigBoostingRollout;
    return useMemo(() => {
        if (!cutRating) return gigs;
        return gigs.map((gig) => ({
            ...gig,
            buying_review_rating_count: undefined,
            buying_review_rating: undefined,
        }));
    }, [gigs, cutRating]);
};
