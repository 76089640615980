import React from 'react';
import { isEmpty, isNil } from 'lodash';
import { translate } from '@fiverr-private/i18n-react';
import pathfinder from '@fiverr-private/pathfinder';
import {
    NavigationCardCarousel,
    NavigationCardProps,
    BiEventData,
    ModelGradientProps,
} from '@fiverr-private/go_shared_ui';
import { getContext } from '@fiverr-private/fiverr_context';
import { useSellerData } from '../../hooks/useSellerData';
import { useSellerAiModels } from '../../hooks/useSellerAiModels';
import { FederatedSeller } from '../../types';

interface AiModelsCarouselWrapperProps extends Partial<React.ComponentProps<typeof NavigationCardCarousel>> {
    pageName: string;
}

export const AiModelsCarousel: React.FC<AiModelsCarouselWrapperProps> = ({ pageName }) => {
    const { sellerName, sellerId } = useSellerData(selectSellerSlice);

    const sellerAIModels = useSellerAiModels();
    if (isNil(sellerAIModels) || isEmpty(sellerAIModels)) return null;

    const { userId, pageCtxId } = getContext();

    const model = sellerAIModels[0];

    const biEventData: BiEventData = {
        ...(userId && { userId }),
        ...(sellerId && { sellerId: Number(sellerId) }),
        ctxId: pageCtxId,
        pageName,
        aiModelId: model.id,
    };

    const aiModelCards: NavigationCardProps[] = sellerAIModels.map(({ id, name, theme }) => {
        const modelGradientProps: ModelGradientProps = {
            ...theme,
            scale: 'm',
            rotate: 0,
            animate: 'hover',
        };

        return {
            id,
            title: name,
            cardSize: 'md',
            modelGradientProps,
            shouldShowButton: true,
            url: pathfinder(
                'ai_hub_playground_create_tab_new_model_conversation',
                {
                    seller_username: sellerName,
                    model_slug: id,
                },
                {
                    query: {
                        source: `${pageName}_mymodels`,
                    },
                }
            ),
        };
    });

    return (
        <NavigationCardCarousel
            tiles={aiModelCards}
            carouselProps={{ maxWidth: 'max-content' }}
            title={translate('seller_pages_toolbox.ai_models_carousel.title')}
            description={translate('seller_pages_toolbox.ai_models_carousel.description')}
            textColor="grey_1100"
            biEventData={biEventData}
        />
    );
};

const selectSellerSlice = (seller: FederatedSeller) => ({
    sellerName: seller.user.name,
    sellerId: seller.user.id,
});
