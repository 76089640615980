import React from 'react';
import { useAtomValue } from 'jotai';
import { ContactSellerButton } from '@fiverr-private/send_message';
import {
    ContactButton,
    FederatedSeller,
    isFloatingChatActiveAtom,
    useIsSellerOnline,
    useMonitoring,
    useSellerData,
} from '@fiverr-private/seller_pages_toolbox';
import { Container } from '@fiverr-private/layout_components';
import { getContext } from '@fiverr-private/fiverr_context';
import { useContactSellerModalUpdater } from '../ContactSellerModal/hooks/useContactSellerModal';
import { useContactMeProfessionBIExtensionData } from '../BIContext';

function selectSellerSlice(seller: FederatedSeller) {
    return {
        username: seller.user.name,
        profilePhoto: seller.user.profileImageUrl,
        responseTimeInHrs: seller.responseTime?.inHours,
        displayName: seller.user.profile.displayName,
    };
}

export const MessageSellerButton = () => {
    const isOnline = useIsSellerOnline();
    const { fireEvents, Providers } = useMonitoring();
    const setShowModal = useContactSellerModalUpdater();
    const { username, profilePhoto, responseTimeInHrs, displayName } = useSellerData(selectSellerSlice);
    const roleBiEnrichment = useContactMeProfessionBIExtensionData();
    const isFloatingChatEnabled = useAtomValue(isFloatingChatActiveAtom);
    const { isMobile } = getContext();

    const openContactModal = () => {
        setShowModal(true);
        fireEvents({
            [Providers.Mixpanel]: {
                type: 'clicked on sticky chat option',
            },
            [Providers.BigQuery]: {
                type: 'clicked_on_sticky_chat_option',
                page: {
                    element: {
                        name: 'role_sticky_chat',
                        text: JSON.stringify({
                            'online/offline': isOnline ? 'online' : 'offline',
                            avg_response_time: responseTimeInHrs ? `${responseTimeInHrs}_hour` : undefined,
                        }),
                    },
                },
                ...roleBiEnrichment,
            },
        });
    };

    if (isMobile) {
        return null;
    }

    return (
        <Container
            display={{
                default: 'none',
                lg: 'inlineBlock',
            }}
            position="sticky"
            left="32px"
            bottom="32px"
            zIndex="2"
        >
            <ContactButton onContactOpen={openContactModal} reportBI={false}>
                <ContactSellerButton
                    recipientInfo={{
                        username,
                        profilePhoto,
                        responseTimeInHrs,
                        isOnline,
                        displayName,
                    }}
                    isAssistantActive={isFloatingChatEnabled}
                />
            </ContactButton>
        </Container>
    );
};
