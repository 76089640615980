import { useQuery } from 'react-query';
import { useSellerData } from '../useSellerData';
import { getIsUserOnline } from '../../apis/getIsUserOnline';
import { FederatedSeller } from '../../types';

const BASE_KEY = 'is-user-online';
const MIN = 1000 * 60;

const useIsUserOnlineQuery = (username: string) => {
    const data = useQuery([BASE_KEY, username], () => getIsUserOnline(username), {
        cacheTime: 0,
        refetchInterval: 3 * MIN,
    });
    return data;
};

export const useIsSellerOnline = () => {
    const { username } = useSellerData(selectSellerSlice);

    const { data: { isOnline = false } = {} } = useIsUserOnlineQuery(username);
    return isOnline;
};

export const useIsUserOnline = (username: string) => {
    const { data: { isOnline = false } = {} } = useIsUserOnlineQuery(username);
    return isOnline;
};

function selectSellerSlice(seller: FederatedSeller) {
    return {
        username: seller.user.name,
    };
}
