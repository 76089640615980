import { useSellerData, type SellerPageFederatedSeller } from '@fiverr-private/seller_pages_toolbox';

export const useSellerDisplayName = (): string => {
    const { displayName, username } = useSellerData(selectSellerSlice);
    return displayName || username;
};

const selectSellerSlice = (seller: SellerPageFederatedSeller) => ({
    displayName: seller.user.profile.displayName,
    username: seller.user.name,
});
