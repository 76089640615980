import React, { useState } from 'react';
import classnames from 'classnames';
import * as colors from '@fiverr-private/sass/helpers';
import { ErrorBoundary } from '@fiverr-private/ui_utils';
import { Tooltip } from '@fiverr-private/tooltip';
import { Icon } from '@fiverr-private/fit';
import { PencilIcon } from '@fiverr-private/icons';
import { I18n } from '@fiverr-private/i18n-react';
import { logger } from '@fiverr-private/obs';
import { Stack } from '@fiverr-private/layout_components';
import { Text } from '@fiverr-private/typography';
import { DisplayNameModal } from '../DisplayNameModal';
import { Languages } from '../Languages';
import { Location } from '../Location';
import { useIsSelfView } from '../../hooks/useIsSelfView';
import { useSellerData } from '../../hooks/useSellerData';
import { useMonitoring } from '../../utils/monitoring';
import { FederatedSeller } from '../../types';
import { ProfileBadgeRatings } from './ProfileBadgeRatings';
import { ProfileAvatar } from './ProfileAvatar';
import { ProfileHeaderProps } from './types';

import styles from './index.module.scss';

const onRenderError = (originalError) => {
    const err = new Error(`Failed while rendering the ProfileHeader Component`);
    logger.error(err, {
        cause: originalError,
    });
};

const ProfileHeader = ({
    className,
    avatarClassName,
    avatarSize = 96,
    onlineIndicatorSize = 16,
    withAvatarBorder,
    isFiverrChoice,
    withH1PublicName = false,
    withGeographicalData = true,
    showSellerLevel = false,
    showSellerLevelNewBadges,
    withOneLiner = true,
    ratingsClassName,
    isInModal = false,
    onRatingsClick,
    disableIntroVideo = false,
    useTestId = false,
    canEdit: forceCanEdit = false,
}: ProfileHeaderProps) => {
    const [displayNameModalVisible, setDisplayNameModalVisible] = useState(false);
    const isCurrentUser = useIsSelfView();
    const { fireEvents, Providers } = useMonitoring();
    const { displayName, username, fullName, oneLinerTitle } = useSellerData(selectSellerSlice);

    const canEdit = (isCurrentUser && !isInModal) || forceCanEdit;

    const onEditOneLinerClickBI = () => {
        fireEvents({
            [Providers.Mixpanel]: {
                type: 'clicked edit public name',
            },
            [Providers.BigQuery]: {
                type: 'clicked_on_edit_name_or_tag_line_seller',
            },
        });
    };

    const closeDisplayNameModal = () => setDisplayNameModalVisible(false);

    const openDisplayNameModal = () => {
        setDisplayNameModalVisible(true);
        onEditOneLinerClickBI();
    };

    return (
        <ErrorBoundary onError={onRenderError}>
            <div className={classnames(className, styles.profileHeader)}>
                <ProfileAvatar
                    disableIntroVideo={disableIntroVideo}
                    withAvatarBorder={withAvatarBorder}
                    className={avatarClassName}
                    avatarSize={avatarSize}
                    onlineIndicatorSize={onlineIndicatorSize}
                />

                <div className={classnames(styles.userInfo, styles.center, 'co-text-darker')}>
                    <ProfileBadgeRatings
                        withH1PublicName={withH1PublicName}
                        showSellerLevel={showSellerLevel}
                        showSellerLevelNewBadges={showSellerLevelNewBadges}
                        className={ratingsClassName}
                        onRatingsClick={onRatingsClick}
                        isFiverrChoice={isFiverrChoice}
                        canEdit={canEdit}
                        onDisplayNameIconClick={openDisplayNameModal}
                        source="header"
                        useTestId={useTestId}
                    />
                    {withOneLiner && !!oneLinerTitle && (
                        <div className="flex flex-items-center flex-start">
                            <Text color="bodyPrimary" size="b_md">
                                {oneLinerTitle}
                            </Text>
                            {canEdit && (
                                <div className="m-l-12">
                                    <Tooltip content={<I18n k="seller_pages_toolbox.display_name.title_tooltip" />}>
                                        <Icon onClick={openDisplayNameModal}>
                                            <PencilIcon color={colors.grey_900} />
                                        </Icon>
                                    </Tooltip>
                                </div>
                            )}
                        </div>
                    )}
                    {withGeographicalData && (
                        <Stack gap="4">
                            <Location />
                            <Languages />
                        </Stack>
                    )}
                </div>
                {canEdit && (
                    <DisplayNameModal
                        displayName={displayName}
                        fullName={fullName}
                        oneLiner={oneLinerTitle}
                        isOpen={displayNameModalVisible}
                        username={username}
                        onClose={closeDisplayNameModal}
                    />
                )}
            </div>
        </ErrorBoundary>
    );
};

function selectSellerSlice(seller: FederatedSeller) {
    return {
        oneLinerTitle: seller.oneLinerTitle,
        displayName: seller.user.profile.displayName,
        username: seller.user.name,
        fullName: seller.user.fullName,
        languages: seller.user.languages,
    };
}

export { ProfileHeader };
