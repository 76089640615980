import React from 'react';
import { Container, Stack } from '@fiverr-private/layout_components';
import { Heading, Text } from '@fiverr-private/typography';
import { I18n } from '@fiverr-private/i18n-react';
import { useSellerData } from '../../hooks/useSellerData';
import { FederatedSeller } from '../../types';

interface VettedByProBannerProps extends React.ComponentProps<typeof Stack> {}

export const VettedByProBanner: React.FC<VettedByProBannerProps> = (props) => {
    const { displayName, username } = useSellerData(selectSellerName);
    const sellerName = displayName || username;
    return (
        <Stack direction="column" gap="3" alignItems="stretch" justifyContent="spaceBetween" {...props}>
            <Heading as="h6" size="h_xxs" fontWeight="semibold" color="heading">
                <I18n k="seller_pages_toolbox.vetted_by_fiverr.title" />
            </Heading>
            <Container>
                <Text color="bodyPrimary">
                    <I18n k="seller_pages_toolbox.vetted_by_fiverr.text" params={{ sellerName }} />
                </Text>
            </Container>
        </Stack>
    );
};

function selectSellerName(seller: FederatedSeller) {
    return {
        displayName: seller.user.profile?.displayName,
        username: seller.user.name,
    };
}
