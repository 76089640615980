import React from 'react';
import { Avatar, AvatarImage } from '@fiverr-private/avatar';
import { useSellerData } from '../../hooks/useSellerData';
import { FederatedSeller } from '../../types';
import { ProAvatarProps } from './types';

export const ProAvatar = ({ avatarSize = 'xl' }: ProAvatarProps) => {
    const { username, profileImageUrl } = useSellerData(selectSellerSlice);

    if (!profileImageUrl) {
        return null;
    }

    return (
        <Avatar size={avatarSize} username={username} showRing={false}>
            <AvatarImage src={profileImageUrl} alt={username} />
        </Avatar>
    );
};

function selectSellerSlice(seller: FederatedSeller) {
    return {
        username: seller.user.name,
        profileImageUrl: seller.user.profileImageUrl,
    };
}
