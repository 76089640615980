import React, { useMemo, useState } from 'react';
import classnames from 'classnames';
import { Avatar } from '@fiverr-private/fit';
import { getContext } from '@fiverr-private/fiverr_context';
import { useBreakpoints } from '@fiverr-private/business_blocks';
import ProfileImage from '@fiverr-private/profile_image';
import { useIsSelfView } from '../../../hooks/useIsSelfView';
import { ProAvatar } from '../../ProAvatar';
import { useSellerData } from '../../../hooks/useSellerData';
import { useIsSellerOnline } from '../../../hooks/useIsSellerOnline';
import { useMonitoring } from '../../../utils/monitoring';
import { FederatedSeller } from '../../../types';
import { ProfileAvatarProps } from './types';
import styles from './index.module.scss';

const POLICY_PATH = '/attachments/policy';
const UPLOAD_PATH = '/profiles/update_photo';

const PLAY_ICON_LARGE_SIZE = 14;
const PLAY_ICON_MEDIUM_SIZE = 12;
const PLAY_ICON_SMALL_SIZE = 10;

export const ProfileAvatar = ({
    disableIntroVideo,
    withAvatarBorder,
    className,
    avatarSize,
    onlineIndicatorSize,
}: ProfileAvatarProps) => {
    const isOnline = useIsSellerOnline();
    const { csrfToken, isMobile } = getContext();
    const { isLG, isMD } = useBreakpoints();
    const { fireEvents, Providers } = useMonitoring();
    const { sellerId, profileImageUrl, username, introVideo, isPro } = useSellerData(selectSellerSlice);

    const introVideoUrl = introVideo?.url;
    const isSelfView = useIsSelfView();
    const [uploadingPhoto, setUploadingPhoto] = useState(false);

    const editableProfilePhoto = isSelfView;
    const avatarClassNames = classnames(
        {
            [styles.avatarContainerBorder]: withAvatarBorder && !editableProfilePhoto && !introVideoUrl,
            'm-r-32': introVideoUrl,
            'm-r-20': !introVideoUrl,
        },
        className
    );

    const avatarClicked = () => {
        if (!uploadingPhoto && editableProfilePhoto) {
            setUploadingPhoto(true);
            fireEvents({
                [Providers.Mixpanel]: {
                    type: 'clicked change profile image',
                },
            });
        }
    };

    const onProfilePhotoChange = () => {
        setUploadingPhoto(false);
        fireEvents({
            [Providers.Mixpanel]: {
                type: 'profile image uploaded',
                isSuccess: true,
            },
        });
    };

    const shouldRenderAvatar = !editableProfilePhoto && !introVideoUrl;
    const shouldRenderWithVideo = introVideoUrl && !editableProfilePhoto && !disableIntroVideo;

    const calcIconSize = () => {
        switch (true) {
            case isLG && !isMobile:
                return PLAY_ICON_LARGE_SIZE;
            case isMD:
                return PLAY_ICON_MEDIUM_SIZE;
            default:
                return PLAY_ICON_SMALL_SIZE;
        }
    };

    const additionalProfileImageProps = useMemo(() => {
        let props = {};

        if (editableProfilePhoto) {
            props = {
                allowEditing: true,
                authToken: csrfToken,
                fetchPolicyPath: POLICY_PATH,
                uploadPhotoPath: UPLOAD_PATH,
                onUploadSuccess: onProfilePhotoChange,
            };
        } else if (shouldRenderWithVideo) {
            // !editableProfilePhoto is part of its definition
            props = {
                introVideo: introVideoUrl,
                mode: ProfileImage?.MODES?.VIDEO_INTENT,
                introVideoPlayButtonIconSize: calcIconSize(),
                introVideoPlayButtonClassName: classnames(styles.introVideoPlayButton, {
                    [styles.sm]: !isMD,
                    [styles.md]: (!isLG && isMD) || (isMobile && isLG),
                    [styles.lg]: !isMobile && isLG,
                }),
            };
        }

        return props;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editableProfilePhoto, shouldRenderWithVideo]);

    const avatarComponent = () => {
        if (isPro) {
            return (
                <div className={avatarClassNames}>
                    <ProAvatar avatarSize="3xl" />
                </div>
            );
        }

        return (
            <Avatar
                size={avatarSize}
                containerClassName={avatarClassNames}
                username={username}
                src={profileImageUrl}
                isOnline={isOnline}
                showOnlineIndicator={isOnline}
                onlineIndicatorSize={onlineIndicatorSize}
            />
        );
    };

    const userProfileComponent = () => {
        const userProps = {
            user_id: sellerId,
            username,
            has_profile_photo: profileImageUrl !== null,
            profile_photo: profileImageUrl,
            achievement: 0,
            is_pro: isPro,
        };

        return (
            <ProfileImage
                className={avatarClassNames}
                profileImageSize={avatarSize}
                user={userProps}
                {...additionalProfileImageProps}
            />
        );
    };

    const profileAvatarComponent = () => {
        if (shouldRenderAvatar) {
            return avatarComponent();
        }

        return userProfileComponent();
    };

    return (
        <div className={styles.userAvatar} onClick={avatarClicked}>
            {profileAvatarComponent()}
        </div>
    );
};

function selectSellerSlice(seller: FederatedSeller) {
    return {
        sellerId: seller.user.id,
        username: seller.user.name,
        profileImageUrl: seller.user.profileImageUrl,
        introVideo: seller.introVideo,
        isPro: seller.isPro,
    };
}
