import { SellerProfession_VariantType } from '@fiverr-private/bianka_js/protobuf/bianka/event/activity/v2/extension/seller_profession';
import { RoleCatalogType, SellerRole } from '../../../../gql-operations/gql-generated';
import { translateRoleTitle } from '../../../utils/translateRoleTitle';
import { ProfessionBIExtensionData } from '../types';

const MAP_ROLE_CATALOG_TYPE_TO_VARIANT_TYPE = {
    [RoleCatalogType.MetadataValue]: SellerProfession_VariantType.MD,
    [RoleCatalogType.Role]: SellerProfession_VariantType.ROLE,
    [RoleCatalogType.Service]: SellerProfession_VariantType.SERVICE,
};

export const buildProfessionBIExtension = (role?: SellerRole | null, variantId?: string): ProfessionBIExtensionData => {
    const result: ProfessionBIExtensionData = {};

    if (role) {
        result.profession = {
            id: role.role.id,
            sellerProfessionId: role.id,
        };

        result.computedTitle = translateRoleTitle(role, variantId);
    }

    if (role && variantId) {
        const variation = role.variations.find((v) => v.id === variantId);

        if (variation) {
            result.variant = {
                id: variation.id,
                type: MAP_ROLE_CATALOG_TYPE_TO_VARIANT_TYPE[variation.type],
            };
        }
    }

    return result;
};
