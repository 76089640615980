import { atom } from 'jotai';
import type { GigData, ReviewsData } from '@fiverr-private/seller_pages_toolbox';
import { Rollouts } from '../server/middlewares/addRolloutsData/constants';
import type { SellerRole } from '../gql-operations/gql-generated';
import type { OrdersData, PortfolioProject } from './types';

export const reviewsDataAtom = atom<ReviewsData | undefined>(undefined);
export const gigsAtom = atom<GigData[]>([]);
export const rolloutsAtom = atom<Rollouts>({});
export const ordersAtom = atom<OrdersData>({});
export const sellerHasProfessionsAtom = atom<boolean>(false);
export const sellerRolesAtom = atom<SellerRole[]>([]);
export const selectedRoleIdAtom = atom<SellerRole['id'] | null>(null);
export const roleToVariationMappingAtom = atom<Record<string, string>>({});
export const portfoliosAtom = atom<PortfolioProject[]>([]);
