import { getContext } from '@fiverr-private/fiverr_context';

export const SUPPORTED_LANGUAGES = ['en-US'];

// Optional context argument to allow server side usage with RequestContext package
export const isAiBriefAvailable = (locale?: string) => {
    const _locale = locale || getContext().locale;
    const isUserLanguageSupported = SUPPORTED_LANGUAGES.includes(_locale);

    return isUserLanguageSupported;
};
