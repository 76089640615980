import React, { useMemo } from 'react';
import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import { getContext } from '@fiverr-private/fiverr_context';
import pathfinder from '@fiverr-private/pathfinder';
import { FreelancerReviews, Reviews, FILTER_TYPES } from '@fiverr-private/reviews';
import { SellerPageFederatedSeller, useLocalizationContext, useSellerData } from '@fiverr-private/seller_pages_toolbox';
import { logger } from '@fiverr-private/obs';
import { ErrorBoundary } from '@fiverr-private/ui_utils';
import { gigsAtom } from '../../atoms';
import { useSellerReviews } from '../../hooks/useSellerReviews';
import { useSelectedRoleId } from '../../hooks/useSelectedRoleId';
import classes from './style.module.scss';

type ReviewsType = 'buyingReviews' | 'sellingReviews';

const onRenderError = (originalError) => {
    const err = new Error(`Failed while rendering the SellerReviews Component`);
    logger.error(err, {
        cause: originalError,
    });
};

interface SellerReviewsProps {
    isReviewItemFullWidth?: boolean;
}

export const SellerReviews: React.FC<SellerReviewsProps> = ({ isReviewItemFullWidth = false }) => {
    const { pageCtxId } = getContext();
    const { currency } = useLocalizationContext();
    const { approvedGigsCount } = useSellerData(selectSellerSlice);
    const reviews = useSellerReviews();
    const selectedRoleId = useSelectedRoleId();
    const gigs = useAtomValue(gigsAtom);

    const buyingReviewsKey = useMemo(
        () => reviews?.buying_reviews?.reviews.map((review) => review.id).join('-'),
        [reviews]
    );
    const sellingReviewsKey = useMemo(
        () => reviews?.selling_reviews?.reviews.map((review) => review.id).join('-'),
        [reviews]
    );

    if (!reviews) {
        return null;
    }

    const { buying_reviews, selling_reviews } = reviews;

    const reviewsBaseConfig = {
        pathFinderConfig: {
            path: 'seller_page_reviews',
        },
        pageCtxId,
        pageName: 'seller_page',
        biEventGroup: 'seller_page',
        role_id: selectedRoleId,
    };

    const showBuyingReviews = buying_reviews?.reviews?.length > 0 && approvedGigsCount > 0;

    const showSellingReviews = selling_reviews?.reviews?.length > 0 && !approvedGigsCount;

    const buyingReviews = {
        ...reviewsBaseConfig,
        ...buying_reviews,
        type: FILTER_TYPES.relevant,
        displayRelevant: true,
        displayBreakdown: true,
        gigsData: gigs,
    };

    const handleFilterChange = (reviewType: ReviewsType) => (filterType) => {
        // eslint-disable-next-line no-console
        console.log('TODO - Send Mixpanel', reviewType, filterType);
    };

    const onLoadMoreClick = (reviewType: ReviewsType) => () => {
        // eslint-disable-next-line no-console
        console.log('TODO - Send Mixpanel', reviewType);
    };

    const sellingReviews = { ...reviewsBaseConfig, ...selling_reviews };
    const reviewsTranslationUrl = pathfinder('seller_page_ugc_translate');

    return (
        <ErrorBoundary onError={onRenderError}>
            <section className={classNames('flex flex-col', classes.reviewsSection)}>
                {showBuyingReviews && (
                    <div id="reviews_header_as_seller">
                        {/* @ts-expect-error | "@fiverr-private/reviews" package was migrated to Package Core V4, but it's still JS-based, so we're temporary suppressing its wrong-compiled types in here */}
                        <FreelancerReviews
                            key={buyingReviewsKey}
                            initialState={buyingReviews}
                            currencyConfig={currency}
                            onFilterChange={handleFilterChange('buyingReviews')}
                            onLoadMoreClick={onLoadMoreClick('buyingReviews')}
                            enableSearchFilter
                            translationUrl={reviewsTranslationUrl}
                            isReviewItemFullWidth={isReviewItemFullWidth}
                        />
                    </div>
                )}

                {showSellingReviews && (
                    <div id="reviews_header_as_buyer">
                        <Reviews
                            key={sellingReviewsKey}
                            initialState={sellingReviews}
                            onFilterChange={handleFilterChange('sellingReviews')}
                            onLoadMoreClick={onLoadMoreClick('sellingReviews')}
                            translationUrl={reviewsTranslationUrl}
                        />
                    </div>
                )}
            </section>
        </ErrorBoundary>
    );
};

function selectSellerSlice(seller: SellerPageFederatedSeller) {
    return {
        approvedGigsCount: seller.approvedGigsCount,
    };
}
